<template>
  <div class="header">
    <div class="title">
      <!-- <h3>Dashboard {{ currentRoute }}</h3> -->
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
        class="menu-button"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <h3 v-if="currentRoute === '/home'">Users</h3>
      <h3 v-else>Operators</h3>
    </div>
    <div class="notifications">
      <v-menu offset-y class="navbar-menu-items">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon large class="hidden-xs-only" v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in navDropdown" :key="index" link>
            <v-list-item-title @click="navAction(item)">
              {{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <ChangePasswordDialog :dialog.sync="showChangePasswordDialog" />
    <v-navigation-drawer v-model="drawer" absolute mobile-breakpoint="800">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="logo-container">
            <img src="@/assets/logo.png" width="120" alt="Orbispay logo" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list dense nav tile class="pa-0">
        <v-list-item
          v-for="item in navItems"
          :key="item.title"
          link
          class="sidenav-items"
          :to="item.route"
        >
          <v-icon medium class="mr-2">{{ item.icon }}</v-icon>
          <span>{{ item.title }}</span>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { bus } from '@/main'
const ChangePasswordDialog = () =>
  import('@/components/Dialog/changePasswordDialog.vue')

export default {
  name: 'Header',
  components: {
    ChangePasswordDialog,
  },
  data: () => ({
    navDropdown: [
      {
        title: 'Change password',
      },
      {
        title: 'Logout',
      },
    ],
    showChangePasswordDialog: false,
    navItems: [],
    right: null,
    drawer: false,
  }),
  computed: {
    ...mapGetters(['isSuperAdmin']),
    currentRoute() {
      return this.$route.path
    },
  },
  methods: {
    navAction(item) {
      if (item.title === 'Logout') {
        this.logout()
      } else if (item.title === 'Change password') {
        this.showChangePasswordDialog = true
      }
    },
    logout() {
      this.$store.commit('reset_user_store')
      this.$store.commit('logout')
      localStorage.clear()
      this.$router.push('/')
    },
  },
  created() {
    bus.$on('sessionExpired', () => {
      this.logout()
    })
  },
  mounted() {
    if (this.isSuperAdmin === true || this.isSuperAdmin === 'true') {
      this.navItems.push({
        title: 'Users',
        icon: 'mdi-account-multiple-outline',
        route: '/home',
      })
      this.navItems.push({
        title: 'Operators',
        icon: 'mdi-view-dashboard',
        route: '/operators',
      })
    } else {
      this.navItems.push({
        title: 'Users',
        icon: 'mdi-account-multiple-outline',
        route: '/home',
      })
    }
  },
}
</script>

<style lang="scss" scoped>
// for sidenav
.header {
  padding: 2% 3%;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.notifications {
  display: flex;
}

.v-list-item {
  display: flex;
  align-items: start;
  border-bottom: 1px solid #eee;
}

.v-list-item:first-of-type,
.v-list-item:last-of-type {
  border: none;
}

.v-list-item .v-list-item__title {
  line-height: 1.6;
}

.notification-icon {
  font-size: 12px;
  margin-right: 10px;
  padding-top: 8px;
}

.navbar-btn {
  box-shadow: none;
}

.menu-button {
  margin-left: -3%;
  position: absolute;
  top: 0;
  left: 5px;
}

.title {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  @media screen and (min-width: 900px) {
    justify-content: flex-start;
    align-items: center;
  }
}
</style>

<style lang="scss" scoped>
.sidenav-items {
  span {
    font-size: 13px;
    color: var(--subtitle);
  }
}

.logo-container {
  text-align: center;

  img {
    width: 150px;
    margin-bottom: 20px;
    margin-top: 15px;
  }
}

.v-list--nav .v-list-item {
  padding: 0 20%;
  border-radius: 0;
}

.v-list .v-list-item--active {
  color: var(--primary);
  background: #fff;
  border-right: 3px solid var(--primary);
}

.help-info-card {
  h4 {
    color: #000;
  }
  p {
    margin-bottom: 0;
    font-size: 13px;
  }
}

.orbispay-footer {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-items: center;

  span {
    font-size: 13px;
    color: var(--subtitle);
  }
}

a.sidenav-items.v-list-item.v-list-item--link.theme--light {
  align-items: center;
}
</style>
